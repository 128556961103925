@keyframes transparentBlackAnimated {
    0% {
        opacity: 0.25;
    }
    25% {
        opacity : 0.5;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0.25;
        transform: translateX(250px);
    }
    25% {
        opacity : 0.5;
        transform: translateX(150px);
    }
    50% {
        opacity: 0.75;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0.25;
        transform: translateX(-250px);
    }
    25% {
        opacity : 0.5;
        transform: translateX(-150px);
    }
    50% {
        opacity: 0.75;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0.25;
        transform: translateY(250px);
    }
    25% {
        opacity : 0.5;
        transform: translateY(150px);
    }
    50% {
        opacity: 0.75;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0.25;
        transform: translateY(-250px);
    }
    25% {
        opacity : 0.5;
        transform: translateY(-150px);
    }
    50% {
        opacity: 0.75;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}